<template>
  <job-media-files-selector
    @input="selectedFiles = $event"
    :result-as-array="true"
    :job-id="jobId"
    :input-name="inputName"
    :readonly="readonly"
  />
</template>

<script>
import BaseInput from '@gid/vue-common/components/inputs/mixins/BaseInput';
import JobMediaFilesSelector from '@gid/vue-common/components/inputs/JobMediaFilesSelector.vue';

export default {
  mixins: [BaseInput],
  components: {
    JobMediaFilesSelector,
  },
  props: {
    inputName: {
      type: String,
    },
    jobId: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedFiles: [],
    };
  },
  watch: {
    selectedFiles() {
      this.emitChange(this.selectedFiles);
    },
  },
  methods: {
    emitChange(files) {
      const value =
        files && files.length > 0
          ? {
              files,
            }
          : null;
      this.commitValue(value);
    },
  },
};
</script>
